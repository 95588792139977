$container-width: 1170px;

$color-gold-light: #e3ce9a;
$color-gold-dark: #afa46a;
$color-gold-darkest: #726b42;

$color-blue-light: #113c64;
$color-blue-dark: #012842;

$color-light: #e5ecef;

@import url("https://use.fontawesome.com/releases/v5.14.0/css/all.css");

@import "slick/slick.scss";

// GLOBAL
html {
    scroll-behavior: smooth;
}

body {
    background-color: $color-light;
}

.container {
    max-width: $container-width;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.button,
input[type="submit"] {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    min-height: 44px;
    max-width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;

    background-color: $color-gold-light;
    color: $color-blue-light;

    font-family: "Idealist Sans";
    font-weight: normal;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
        background-color: $color-blue-light;
        color: white;
    }

    &.full {
        width: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Idealist Sans";
    font-weight: normal;
    font-style: normal;
}

h1,
.h1-size {
    font-size: 50px;
    line-height: normal;
    color: $color-blue-dark;
}

h2,
.h2-size {
    font-size: 40px;
    line-height: normal;
    letter-spacing: 1px;
    font-weight: bold;
    color: $color-blue-light;
}

h3,
.h3-size {
    font-size: 33px;
    line-height: normal;
    font-weight: bold;
    color: $color-blue-light;
}

h4,
.h4-size {
    font-size: 30px;
    line-height: normal;
    font-weight: normal;
    color: $color-blue-light;
    margin-bottom: 10px;
    opacity: 0.5;
}

a {
    color: inherit;
}

.text {
    &-transform {
        &-uppercase {
            text-transform: uppercase;
        }
    }

    &-align {
        &-left {
            text-align: left;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }
    }
}

.color {
    &-blue {
        &-light {
            color: $color-blue-light !important;
        }
        &-dark {
            color: $color-blue-dark !important;
        }
    }

    &-gold {
        &-light {
            color: $color-gold-light !important;
        }
        &-dark {
            color: $color-gold-dark !important;
        }
        &-darkest {
            color: $color-gold-darkest !important;
        }
    }
}

.screen-reader-text {
    position: absolute;
    margin: -1px;
    padding: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
    word-wrap: normal !important;
}

.golden-box {
    border: 7px solid $color-gold-light;
    padding: 50px;
}

// HEADER
header {
    &#main-header {
        background-color: #fff;
        border-bottom: 4px solid $color-gold-light;

        .topbar {
            background-color: $color-light;

            & > .topbar-wrapper {
                height: 30px;
                display: flex;
                gap: 20px;
                justify-content: flex-end;
                align-items: center;

                & > a {
                    color: $color-blue-dark;
                    font-size: 12px;

                    i {
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $color-gold-darkest;
                    }
                }
            }
        }

        .header {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            .logo {
                width: 2.5in;

                & > a {
                    display: block;

                    & > img {
                        display: block;
                        max-width: 100%;
                    }
                }
            }
        }

        nav {
            height: inherit;

            a {
                font-family: "Idealist Sans";
                font-weight: normal;
                font-style: normal;
            }

            & > ul {
                height: inherit;
                text-align: right;

                & > li {
                    height: inherit;
                    display: inline-block;
                    position: relative;

                    & > a {
                        height: inherit;
                        text-transform: uppercase;
                        color: $color-blue-light;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 30px;
                        font-weight: normal;

                        &:hover,
                        &:focus,
                        &[aria-current="page"] {
                            background-color: $color-gold-light;
                            color: $color-blue-light;
                        }

                        &:hover,
                        &:focus {
                            & + ul {
                                display: block;
                            }
                        }
                    }

                    &:hover {
                        & > a {
                            background-color: $color-gold-light;
                            color: $color-blue-light;

                            & + ul {
                                display: block;
                            }
                        }
                    }

                    & > ul {
                        display: none;
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        top: 100%;
                        min-width: 100%;
                        text-align: left;
                        background-color: $color-blue-dark;
                        border-left: 4px solid $color-gold-dark;

                        &:hover {
                            display: block;
                        }

                        & > li {
                            position: relative;

                            & > a {
                                padding: 15px 20px;
                                color: white;
                                white-space: nowrap;
                                display: block;

                                &:hover,
                                &:focus,
                                &[aria-current="page"] {
                                    background-color: $color-gold-light;
                                    color: $color-blue-light;
                                }

                                &[aria-current="page"] {
                                    &:hover {
                                        background-color: $color-blue-dark;
                                        color: white;
                                    }
                                }

                                &:hover,
                                &:focus {
                                    & + ul {
                                        display: block;
                                    }
                                }
                            }

                            &:hover {
                                & > a {
                                    background-color: $color-gold-light;
                                    color: $color-blue-light;

                                    & + ul {
                                        display: block;
                                    }
                                }
                            }

                            & > ul {
                                display: none;
                                position: absolute;
                                top: 0;
                                left: 100%;
                                background-color: $color-gold-light;
                                border-left: 4px solid $color-gold-dark;

                                &:hover {
                                    display: block;
                                }

                                & > li {
                                    & > a {
                                        color: $color-blue-dark;
                                        padding: 15px 20px;
                                        white-space: nowrap;
                                        display: block;

                                        &:hover,
                                        &:focus,
                                        &[aria-current="page"] {
                                            background-color: $color-blue-dark;
                                            color: white;
                                        }

                                        &:hover,
                                        &:focus {
                                            & + ul {
                                                display: block;
                                            }
                                        }
                                    }

                                    &:hover {
                                        & > a {
                                            background-color: $color-blue-dark;
                                            color: white;

                                            & + ul {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &#subheader {
        background-color: $color-blue-light;

        & > .subheader-wrapper {
            height: 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                color: white;
                font-size: 24px;
                letter-spacing: 1px;
                font-weight: lighter;
            }

            .description {
                color: white;
                font-size: 14px;
            }

            nav {
                display: flex;
                align-items: center;
                gap: 10px;

                & > a {
                    &:hover,
                    &.active {
                        color: $color-gold-light;
                    }

                    &.active {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

// MAIN
main {
    & > section {
        padding-top: 40px;
        padding-bottom: 40px;

        &.white-bg {
            background-color: #fff;
        }

        p {
            font-size: 16px;
        }

        ul:not(.no-style) {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > li::before {
                content: "";
                font-size: 16px;
                line-height: 25px;
                padding-left: 30px;
                background-image: url(/wp-content/themes/davispartners/images/icon-s.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 0 50%;

                & > a {
                    &:hover {
                        color: $color-blue-light;
                    }
                }
            }
        }
    }

    section.headline {
        min-height: calc(100vh - 100px - 8px);
        min-height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-bottom: 4px solid $color-gold-light;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-blue-light;
        position: relative;

        &.autoheight {
            min-height: auto;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: $color-light;
            opacity: 0.7;
        }

        & > .headline-wrapper {
            position: relative;

            h1 {
                font-size: 50px;
            }

            p {
                font-size: 30px;
                color: $color-gold-darkest;
            }
        }
    }

    section.news {
        background-color: $color-blue-dark;
        color: white;
        border-bottom: 4px solid $color-gold-light;

        & > .news-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 40px;

            .post {
                & > a {
                    display: block;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .thumbnail {
                    & > img {
                        width: 100%;
                    }
                }

                .title {
                    margin-top: 15px;
                    margin-bottom: 10px;
                    color: $color-gold-light;
                    font-size: 16px;
                    line-height: 20px;
                    text-transform: uppercase;
                }

                .description {
                    font-size: 13px;
                    font-weight: lighter;
                }
            }
        }
    }
}

.sub-categori-title {
    color: white;
    margin: 50px 0 30px 0;
    font-size: 30px;
}

.porperties-more-button {
    margin: 50px 0;
}

// FOOTER
footer#main-footer {
    background-color: $color-blue-light;
    border-top: 4px solid $color-gold-light;
    color: white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white;
    }

    h3 {
        font-size: 22px;
        font-weight: normal;
    }

    & > .disclosure {
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        padding: 50px 0;

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;

            & > li {
                // font-size: 16px;
                // font-style: italic;
                // padding-left: 30px;
                // background-image: url(/wp-content/themes/davispartners/images/icon-s.png);
                // background-size: contain;
                // background-repeat: no-repeat;
                // background-position: 0 50%;

                & > span {
                    opacity: 0.7;
                }
            }
        }
    }

    & > .footr-wrapper {
        padding: 50px 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 32%;

        h3 {
            margin-bottom: 20px;
        }

        ul {
            & > li {
                margin-bottom: 10px;

                & > a {
                    font-size: 14px;
                    color: white;

                    &:hover {
                        color: $color-gold-light;
                    }
                }
            }
        }

        .partners {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            padding: 0 50px;

            img {
                max-width: 50px;
            }
        }
    }

    & > .bottombar {
        background-color: $color-blue-dark;

        & > .bottombar-wrapper {
            height: 40px;
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            color: white;
            font-size: 12px;

            & > a {
                color: white;
                font-size: 12px;
            }

            p.copyright {
                opacity: 0.7;
            }

            p.social {
                & > a {
                    opacity: 0.7;
                    display: flex;
                    gap: 10px;
                    justify-content: space-between;
                    align-items: center;

                    img {
                        display: block;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#careers {
    .img-gallery {
        ul {
            grid-template-columns: repeat(5, 1fr);
            display: grid;
            gap: 40px;

            img {
                max-height: 284px;
            }
        }
    }
}

#properties {
    .single-property {
        h3 {
            margin-top: 15px;
            margin-bottom: 5px;
            color: #e3ce9a;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
        }

        .property-city {
            margin-top: 0;
            font-size: 15px;
            font-style: italic;
        }
    }
}

.case-studies {
    & > .post {
        display: grid;
        grid-template-columns: 1fr 400px;
        gap: 40px;
        margin-bottom: 40px;

        .title,
        .description {
            margin-bottom: 15px;
        }
    }
}

#properties {
    .single-property {
        div {
            min-height: 155px;
            height: 155px;
            width: 100%;
            background-size: cover;

            img {
                height: 100%;
            }
        }
    }
}

//HOME PAGE
main#home {
    .home-white-section {
        .section-wrapper {
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 30px;
        }
    }
}

// PROPERTIES
main#single-property {
    .section-wrapper {
        display: grid;
        grid-template-columns: 55% 1fr;
        gap: 40px;

        a {
            color: $color-blue-light;

            &:hover {
                color: $color-gold-dark;
            }
        }

        .images {
            img {
                width: 100%;
                height: auto;
                display: block;
            }

            .thumbs {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-top: 20px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .row {
                display: grid;
                grid-template-columns: 180px 1fr;
                gap: 10px;

                .title {
                    color: $color-blue-dark;
                    font-family: "Idealist Sans";
                    font-weight: normal;
                    font-style: normal;
                    font-size: 20px;
                }

                .description {
                    font-size: 15px;
                }
            }

            .agent {
                margin-top: 40px;

                .name {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    font-size: 17px;
                }
            }
        }
    }
}

// SERVICES
main#services {
    & > section:not(.headline):not(.call-to-action) {
        padding-top: 60px;
        padding-bottom: 60px;

        h2 {
            font-size: 25px;
            margin-top: 50px;
            opacity: 0.5;

            &:first-child {
                margin-top: 0;
            }
        }

        h3 {
            font-size: 40px;
            margin-top: 40px;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
            line-height: 25px;
        }
    }
}

.services {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .title {
        margin-top: 25px;
        margin-bottom: 20px;
    }
}

.white-color {
    color: white;
}

// ABOUT
main#about {
    section:not(.headline):not(.call-to-action) h3 {
        font-size: 40px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    section:not(.headline):not(.call-to-action) h2 {
        font-size: 25px;
        margin-top: 50px;
        opacity: 0.5;
    }

    #birtcher-anderson {
        h2 {
            margin-bottom: 20px;
        }
    }

    p {
        line-height: 25px;
        margin-bottom: 20px;
    }

    blockquote {
        text-align: right;
        font-weight: bold;
    }

    #team {
        .team-group {
            .single-team {
                & > a {
                    figure {
                        border-radius: 200px;
                        border: 10px solid $color-gold-light;
                        height: 200px;
                        width: 200px;
                        overflow: hidden;
                        display: inline-block;
                        transition: 0.3s;
                    }

                    h4 {
                        margin-top: 20px;
                        font-size: 25px;
                        transition: 0.3s;
                    }

                    &:hover {
                        figure {
                            opacity: 0.8;
                        }

                        h4 {
                            color: $color-gold-dark;
                        }
                    }
                }
            }

            h3 {
                margin-top: 30px;
            }
        }
    }

    .client-group,
    .partner-and-lenders-group,
    .award-slider {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: 100px;
    }
}

.members-category {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}

.member-depertman-title {
    margin: 70px 0 40px 0 !important;
}

.client-group {
    .list-column {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        width: 100%;
    }
}

#single-team {
    .section-wrapper {
        display: grid;
        grid-template-columns: 35% 1fr;
        gap: 20px;

        .images {
            text-align: center;

            img {
                border-right: 10px solid $color-gold-light;
                border-bottom: 10px solid $color-gold-light;
            }
        }

        h3 {
            font-size: 23px;
            margin: 20px 0;
            opacity: 0.5;
            font-weight: 100;
        }

        p {
            line-height: 27px;
        }
    }
}

.blue-menu {
    & > ul li {
        display: inline-block;
        position: relative;

        &:hover {
            .blue-field-sub-menu {
                display: block;
            }
        }

        .blue-field-sub-menu {
            z-index: 9;
            display: none;
            position: absolute;
            background-color: #113c64;
            top: 15px;
            padding: 40px 10px 10px 10px;
            width: 200px;

            li {
                padding-bottom: 10px;
                &:hover {
                    color: #e3ce9a;
                }
            }
        }
    }
}

// CONTACT US
main#contact {
    padding-top: 50px;
    padding-bottom: 50px;

    .contact-wrapper {
        display: flex;
        grid-template-columns: auto auto;
        align-items: center;

        h2 {
            font-family: "Idealist Sans";
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        .contact-top-part-txt {
            font-size: 20px;
        }

        .map-direction {
            a {
                color: $color-gold-dark;
                font-size: 20px;

                &:hover {
                    color: $color-blue-light;
                }
            }
        }

        .contact-form-field {
            margin-top: 30px;

            label {
                margin-bottom: 10px;
                display: inline-block;
                color: $color-blue-dark;
            }

            input[type="text"],
            input[type="tel"],
            input[type="email"],
            textarea {
                width: 100%;
                margin-bottom: 20px;
                font-family: "Idealist Sans";
                font-weight: normal;
                background-color: $color-gold-light;
                color: $color-blue-dark;
                box-sizing: border-box;
            }

            input[type="text"],
            input[type="tel"],
            input[type="email"] {
                min-height: 40px;
                padding-left: 10px;
            }

            textarea {
                border: none;
                padding: 10px;
                resize: none;
            }

            input[type="submit"] {
                background-color: $color-blue-light;
                color: white;
                width: 50%;

                &:hover {
                    color: $color-blue-light;
                    background-color: white;
                }
            }
        }
    }
}

.description.blue-menu {
    display: none !important;
}

article {
    background-color: #e5ecef;
    padding: 50px 0;

    .post-thumbnail {
        display: inline-block;

        img {
            border-right: 10px solid #e3ce9a;
            border-bottom: 10px solid #e3ce9a;
        }
    }

    .entry-title {
        font-size: 29px !important;
        color: $color-blue-dark;
        line-height: 37px;
        margin: 25px 0;
    }

    p {
        font-size: 17px !important;
        line-height: 25px !important;
    }
}

.mobile-menu {
    display: none;
}

.careers-accordion {
    .accordion-container {
        position: relative;
        height: auto;
        width: 100%;
    }
    .accordion-container > h2 {
        text-align: center;
        color: #fff;
        padding-bottom: 5px;
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
    }
    .set {
        position: relative;
        width: 100%;
        height: auto;
        background-color: #f5f5f5;
    }
    .set > a {
        display: block;
        padding: 20px 15px;
        text-decoration: none;
        color: #555;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }
    .set > a i {
        float: right;
        margin-top: 2px;
    }
    .set > a.active {
        background-color: #3399cc;
        color: #fff;
    }
    .content {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        display: none;
        padding: 20px;
    }
    .content p {
        padding: 10px 15px;
        margin: 0;
        color: #333;
    }
}

.accordion-main-title {
    font-size: 20px !important;
    color: #113c64;
    background-color: #e3ce9a;
    margin: 0 !important;
    padding: 20px;
    font-weight: bold;
}

.carousel-gallery {
    margin: 50px 0;
    padding: 0 30px;
    .swiper-slide {
        a {
            display: block;
            width: 100%;
            height: 200px;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            -webkit-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);

            &:hover {
                .image {
                    .overlay {
                        opacity: 1;
                    }
                }
            }

            .image {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center center;
                .overlay {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(20, 20, 20, 0.8);
                    text-align: center;
                    opacity: 0;

                    -webkit-transition: all 0.2s linear;
                    -o-transition: all 0.2s linear;
                    transition: all 0.2s linear;

                    em {
                        color: #fff;
                        font-size: 26px;
                        position: relative;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        display: inline-block;
                    }
                }
            }
        }
    }
    .swiper-pagination {
        position: relative;
        bottom: auto;
        text-align: center;
        margin-top: 25px;

        .swiper-pagination-bullet {
            -webkit-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &:hover {
                opacity: 0.7;
            }

            &.swiper-pagination-bullet-active {
                background-color: #d63031;
                transform: scale(1.1, 1.1);
            }
        }
    }
}

.property-main-title {
    color: #e3ce9a;
    margin: 50px 0 30px 0;
}

header#main-header nav > ul > li > ul > li > a:hover {
    background-color: #e3ce9a !important;
    color: #113c64 !important;
}
header#main-header nav > ul > li > ul > li > a:focus {
    background-color: #e3ce9a !important;
    color: #113c64;
}
header#main-header nav > ul > li > ul > li > a[aria-current="page"] {
    background-color: transparent;
    color: white;
}

/////////////////////////////////////////// DEVICE LG
@media only screen and (max-width: 1199px) {
    #home {
        .news-wrapper {
            grid-template-columns: repeat(2, 1fr);
        }

        .slick-arrow {
            position: absolute;
            top: -40%;
            font-size: 30px;
            cursor: pointer;

            &.slick-next {
                right: 0px;
            }

            &.slick-prev {
                left: 0px;
            }
        }
    }

    header#main-header nav > ul > li > a {
        padding: 0px 15px;
    }

    .footr-wrapper.container::before {
        display: none;
    }

    .careers-warp .curr-open {
        width: 34%;
    }
}

////////////////////////////////////////////// DEVICE MD

@media only screen and (max-width: 991px) {
    header#main-header nav > ul > li > a {
        padding: 0 2px;
        font-size: 11px;
    }

    .post.porperty-wrapper {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .footr-wrapper.container {
        grid-template-columns: repeat(2, 1fr) !important;

        & div {
            margin-bottom: 50px;
        }
    }

    main#contact .contact-wrapper {
        display: block;
    }

    main section.news > .news-wrapper {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .case-studies > .post {
        display: grid;
        grid-template-columns: 1fr 200px;
    }

    .careers-warp .curr-open {
        width: 37%;
    }

    #careers .img-gallery ul {
        grid-template-columns: repeat(3, 1fr);
    }

    .single-prpoerty-list {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .propertyid-main-figure {
        text-align: center;

        img {
            width: inherit;
        }
    }
}

/////////////////////////////////////////// DEVICE SM
@media only screen and (max-width: 767px) {
    main section.headline {
        min-height: auto;
    }
    #home {
        .news-wrapper {
            grid-template-columns: repeat(1, 1fr);
        }

        .slick-arrow {
            position: absolute;
            top: 100%;
            font-size: 30px;
            cursor: pointer;

            &.slick-prev {
                display: none !important;
            }
        }

        .slick-dots {
            display: none !important;
        }
    }

    #subheader {
        .description {
            display: none !important;
        }
    }

    main#home .home-white-section .section-wrapper {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .golden-box {
        padding: 20px;
    }

    .mobile-menu {
        display: block;
        position: absolute;
        right: 20px;
        font-size: 30px;
        color: #e3ce9a;
        cursor: pointer;
    }

    header {
        nav {
            display: none;

            position: absolute;
            top: 100px;
            left: 0;
            z-index: 9;
            background-color: #012842;
            width: 100%;
            height: auto !important;

            ul {
                padding-bottom: 30px;
                li {
                    display: block !important;
                    text-align: center;
                    height: auto !important;

                    a {
                        color: white !important;
                        display: block;
                        padding: 15px 10px !important;
                        font-size: 15px !important;
                    }

                    .sub-menu {
                        display: none !important;
                    }
                }
            }
        }
    }

    .copyright {
        width: 50%;
    }

    .services {
        display: block;
    }

    main#services > section:not(.headline):not(.call-to-action) {
        padding-top: 20px;
        padding-bottom: 18px;
    }

    main#about #team .team-group {
        grid-template-columns: repeat(2, 1fr);
    }

    main#about .client-group,
    main#about .partner-and-lenders-group,
    main#about .award-slider {
        display: block;
    }

    main#about .client-group {
        margin-bottom: 0;
    }

    .careers-warp .curr-open {
        width: 100%;
    }

    .careers-warp {
        min-height: 630px !important;
    }

    .post.porperty-wrapper {
        grid-template-columns: repeat(3, 1fr) !important;
        display: grid;
        gap: 40px;
    }

    .members-category {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

    .client-group .list-column {
        grid-template-columns: repeat(2, 1fr);
    }

    #single-taem .section-wrapper .grid-2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

/////////////////////////////////////////// DEVICE XS
@media only screen and (max-width: 479px) {
    header#main-header .topbar > .topbar-wrapper {
        display: block;
        height: 50px;

        a {
            display: block;
            margin: 10px 0;
        }
    }

    .post.single-team {
        text-align: center;
    }

    .client-group .list-column {
        grid-template-columns: repeat(1, 1fr);
    }

    .members-category {
        grid-template-columns: repeat(1, 1fr);
    }

    main#home .home-white-section .section-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .footr-wrapper.container {
        grid-template-columns: repeat(1, 1fr) !important;
        text-align: center;
    }

    main#about #team .team-group {
        grid-template-columns: repeat(1, 1fr);
    }

    main#about .partner-and-lenders-group {
        margin-bottom: 0;
    }

    header nav {
        top: 130px !important;
    }

    main section.news > .news-wrapper {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .case-studies > .post {
        display: inherit;

        .info {
            display: block;
        }

        figure {
            display: block;
        }
    }

    .careers-warp {
        min-height: 870px !important;
    }

    .post.porperty-wrapper {
        grid-template-columns: repeat(1, 1fr) !important;
        display: grid;
        gap: 40px;
    }

    #careers {
        .img-gallery ul {
            grid-template-columns: repeat(2, 1fr);
        }
        .accordion-main-title {
            font-size: 14px !important;
        }
    }
}

.single-prpoerty-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.post.porperty-wrapper {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 40px;

    h3.title {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #e3ce9a;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
    }

    // figure {
    //     img {
    //         min-height: 130px;
    //         max-height: 130px;
    //     }
    // }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 30px;
    cursor: pointer;

    &.slick-next {
        right: -80px;
    }

    &.slick-prev {
        left: -80px;
    }
}

#home {
    section.headline > .headline-wrapper p {
        font-size: 22px !important;
        line-height: 31px;
    }

    .slick-dots {
        display: inline-block;
        position: absolute;
        bottom: -100px;
        li {
            display: inline-block;

            &:before {
                display: none;
            }

            button {
                color: #012842;
                background-color: #e3ce9a;
                border: none;
                padding: 13px;
                margin-left: 10px;
            }
        }
        .slick-active {
            button {
                color: #e3ce9a !important;
                background-color: #012842 !important;
            }
        }
    }
}

.accordion-title {
    text-align: center;
    font-size: 23px;
    font-weight: 600 !important;
    color: #353535;
    opacity: 1 !important;
}

.property-info {
    .row {
        margin: 30px 0;
        grid-template-columns: 35% 1fr;
        display: grid;
        gap: 40px;
    }
    .title {
        color: #012842;
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        // font-weight: bold;
    }

    .description {
        font-size: 15px;
    }
}

.img-gallery {
    li {
        &::before {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    ul {
        grid-template-columns: repeat(3, 1fr);
        display: grid !important;
        gap: 40px !important;
        margin-top: 30px;
    }
}

footer#main-footer > .disclosure ul > li {
    &::before {
        content: "";
        font-size: 16px;
        font-style: italic;
        padding-left: 30px;
        background-image: url(/wp-content/themes/davispartners/images/icon-s.png);
        -moz-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 50%;
    }
}

.footr-wrapper.container::before {
    display: none;
}

footer#main-footer > .disclosure ul {
    display: block;
}

#single-taem {
    .section-wrapper .grid-2 {
        display: grid;
        grid-template-columns: 35% 1fr;
        gap: 20px;
        margin-top: 30px;
    }

    .member-photo {
        figure {
            width: 100%;
        }

        img {
            border-right: 10px solid #e3ce9a;
            border-bottom: 10px solid #e3ce9a;
            height: auto;
            width: auto;
        }
    }

    .member-info {
        h3,
        h4,
        h5,
        h6 {
            font-size: 23px;
            margin: 20px 0;
            opacity: 0.5;
            font-weight: 100;
        }

        p {
            line-height: 27px;
        }
    }
}

.headline-logos {
    text-align: center;
    margin-top: 20px;
    figure {
        display: inline-block;
        margin-left: 20px;

        img {
            height: auto;
            width: 200px;
        }
    }
}
