@import url("https://use.fontawesome.com/releases/v5.14.0/css/all.css");
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #e5ecef;
}

.container {
  max-width: 1170px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.button,
input[type=submit] {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  min-height: 44px;
  max-width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  background-color: #e3ce9a;
  color: #113c64;
  font-family: "Idealist Sans";
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.3s;
}
.button:hover,
input[type=submit]:hover {
  background-color: #113c64;
  color: white;
}
.button.full,
input[type=submit].full {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Idealist Sans";
  font-weight: normal;
  font-style: normal;
}

h1,
.h1-size {
  font-size: 50px;
  line-height: normal;
  color: #012842;
}

h2,
.h2-size {
  font-size: 40px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: bold;
  color: #113c64;
}

h3,
.h3-size {
  font-size: 33px;
  line-height: normal;
  font-weight: bold;
  color: #113c64;
}

h4,
.h4-size {
  font-size: 30px;
  line-height: normal;
  font-weight: normal;
  color: #113c64;
  margin-bottom: 10px;
  opacity: 0.5;
}

a {
  color: inherit;
}

.text-transform-uppercase {
  text-transform: uppercase;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.color-blue-light {
  color: #113c64 !important;
}
.color-blue-dark {
  color: #012842 !important;
}
.color-gold-light {
  color: #e3ce9a !important;
}
.color-gold-dark {
  color: #afa46a !important;
}
.color-gold-darkest {
  color: #726b42 !important;
}

.screen-reader-text {
  position: absolute;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
  word-wrap: normal !important;
}

.golden-box {
  border: 7px solid #e3ce9a;
  padding: 50px;
}

header#main-header {
  background-color: #fff;
  border-bottom: 4px solid #e3ce9a;
}
header#main-header .topbar {
  background-color: #e5ecef;
}
header#main-header .topbar > .topbar-wrapper {
  height: 30px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}
header#main-header .topbar > .topbar-wrapper > a {
  color: #012842;
  font-size: 12px;
}
header#main-header .topbar > .topbar-wrapper > a i {
  margin-right: 10px;
}
header#main-header .topbar > .topbar-wrapper > a:hover {
  color: #726b42;
}
header#main-header .header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
header#main-header .header .logo {
  width: 2.5in;
}
header#main-header .header .logo > a {
  display: block;
}
header#main-header .header .logo > a > img {
  display: block;
  max-width: 100%;
}
header#main-header nav {
  height: inherit;
}
header#main-header nav a {
  font-family: "Idealist Sans";
  font-weight: normal;
  font-style: normal;
}
header#main-header nav > ul {
  height: inherit;
  text-align: right;
}
header#main-header nav > ul > li {
  height: inherit;
  display: inline-block;
  position: relative;
}
header#main-header nav > ul > li > a {
  height: inherit;
  text-transform: uppercase;
  color: #113c64;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  font-weight: normal;
}
header#main-header nav > ul > li > a:hover, header#main-header nav > ul > li > a:focus, header#main-header nav > ul > li > a[aria-current=page] {
  background-color: #e3ce9a;
  color: #113c64;
}
header#main-header nav > ul > li > a:hover + ul, header#main-header nav > ul > li > a:focus + ul {
  display: block;
}
header#main-header nav > ul > li:hover > a {
  background-color: #e3ce9a;
  color: #113c64;
}
header#main-header nav > ul > li:hover > a + ul {
  display: block;
}
header#main-header nav > ul > li > ul {
  display: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  min-width: 100%;
  text-align: left;
  background-color: #012842;
  border-left: 4px solid #afa46a;
}
header#main-header nav > ul > li > ul:hover {
  display: block;
}
header#main-header nav > ul > li > ul > li {
  position: relative;
}
header#main-header nav > ul > li > ul > li > a {
  padding: 15px 20px;
  color: white;
  white-space: nowrap;
  display: block;
}
header#main-header nav > ul > li > ul > li > a:hover, header#main-header nav > ul > li > ul > li > a:focus, header#main-header nav > ul > li > ul > li > a[aria-current=page] {
  background-color: #e3ce9a;
  color: #113c64;
}
header#main-header nav > ul > li > ul > li > a[aria-current=page]:hover {
  background-color: #012842;
  color: white;
}
header#main-header nav > ul > li > ul > li > a:hover + ul, header#main-header nav > ul > li > ul > li > a:focus + ul {
  display: block;
}
header#main-header nav > ul > li > ul > li:hover > a {
  background-color: #e3ce9a;
  color: #113c64;
}
header#main-header nav > ul > li > ul > li:hover > a + ul {
  display: block;
}
header#main-header nav > ul > li > ul > li > ul {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #e3ce9a;
  border-left: 4px solid #afa46a;
}
header#main-header nav > ul > li > ul > li > ul:hover {
  display: block;
}
header#main-header nav > ul > li > ul > li > ul > li > a {
  color: #012842;
  padding: 15px 20px;
  white-space: nowrap;
  display: block;
}
header#main-header nav > ul > li > ul > li > ul > li > a:hover, header#main-header nav > ul > li > ul > li > ul > li > a:focus, header#main-header nav > ul > li > ul > li > ul > li > a[aria-current=page] {
  background-color: #012842;
  color: white;
}
header#main-header nav > ul > li > ul > li > ul > li > a:hover + ul, header#main-header nav > ul > li > ul > li > ul > li > a:focus + ul {
  display: block;
}
header#main-header nav > ul > li > ul > li > ul > li:hover > a {
  background-color: #012842;
  color: white;
}
header#main-header nav > ul > li > ul > li > ul > li:hover > a + ul {
  display: block;
}
header#subheader {
  background-color: #113c64;
}
header#subheader > .subheader-wrapper {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header#subheader > .subheader-wrapper .title {
  color: white;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: lighter;
}
header#subheader > .subheader-wrapper .description {
  color: white;
  font-size: 14px;
}
header#subheader > .subheader-wrapper nav {
  display: flex;
  align-items: center;
  gap: 10px;
}
header#subheader > .subheader-wrapper nav > a:hover, header#subheader > .subheader-wrapper nav > a.active {
  color: #e3ce9a;
}
header#subheader > .subheader-wrapper nav > a.active {
  font-weight: bold;
}

main > section {
  padding-top: 40px;
  padding-bottom: 40px;
}
main > section.white-bg {
  background-color: #fff;
}
main > section p {
  font-size: 16px;
}
main > section ul:not(.no-style) {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
main > section ul:not(.no-style) > li::before {
  content: "";
  font-size: 16px;
  line-height: 25px;
  padding-left: 30px;
  background-image: url(/wp-content/themes/davispartners/images/icon-s.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
main > section ul:not(.no-style) > li::before > a:hover {
  color: #113c64;
}
main section.headline {
  min-height: calc(100vh - 100px - 8px);
  min-height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-bottom: 4px solid #e3ce9a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #113c64;
  position: relative;
}
main section.headline.autoheight {
  min-height: auto;
}
main section.headline::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #e5ecef;
  opacity: 0.7;
}
main section.headline > .headline-wrapper {
  position: relative;
}
main section.headline > .headline-wrapper h1 {
  font-size: 50px;
}
main section.headline > .headline-wrapper p {
  font-size: 30px;
  color: #726b42;
}
main section.news {
  background-color: #012842;
  color: white;
  border-bottom: 4px solid #e3ce9a;
}
main section.news > .news-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}
main section.news > .news-wrapper .post > a {
  display: block;
}
main section.news > .news-wrapper .post > a:hover {
  opacity: 0.7;
}
main section.news > .news-wrapper .post .thumbnail > img {
  width: 100%;
}
main section.news > .news-wrapper .post .title {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #e3ce9a;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}
main section.news > .news-wrapper .post .description {
  font-size: 13px;
  font-weight: lighter;
}

.sub-categori-title {
  color: white;
  margin: 50px 0 30px 0;
  font-size: 30px;
}

.porperties-more-button {
  margin: 50px 0;
}

footer#main-footer {
  background-color: #113c64;
  border-top: 4px solid #e3ce9a;
  color: white;
}
footer#main-footer h1,
footer#main-footer h2,
footer#main-footer h3,
footer#main-footer h4,
footer#main-footer h5,
footer#main-footer h6 {
  color: white;
}
footer#main-footer h3 {
  font-size: 22px;
  font-weight: normal;
}
footer#main-footer > .disclosure {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding: 50px 0;
}
footer#main-footer > .disclosure ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
footer#main-footer > .disclosure ul > li > span {
  opacity: 0.7;
}
footer#main-footer > .footr-wrapper {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 32%;
}
footer#main-footer > .footr-wrapper h3 {
  margin-bottom: 20px;
}
footer#main-footer > .footr-wrapper ul > li {
  margin-bottom: 10px;
}
footer#main-footer > .footr-wrapper ul > li > a {
  font-size: 14px;
  color: white;
}
footer#main-footer > .footr-wrapper ul > li > a:hover {
  color: #e3ce9a;
}
footer#main-footer > .footr-wrapper .partners {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 0 50px;
}
footer#main-footer > .footr-wrapper .partners img {
  max-width: 50px;
}
footer#main-footer > .bottombar {
  background-color: #012842;
}
footer#main-footer > .bottombar > .bottombar-wrapper {
  height: 40px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
}
footer#main-footer > .bottombar > .bottombar-wrapper > a {
  color: white;
  font-size: 12px;
}
footer#main-footer > .bottombar > .bottombar-wrapper p.copyright {
  opacity: 0.7;
}
footer#main-footer > .bottombar > .bottombar-wrapper p.social > a {
  opacity: 0.7;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
footer#main-footer > .bottombar > .bottombar-wrapper p.social > a img {
  display: block;
}
footer#main-footer > .bottombar > .bottombar-wrapper p.social > a:hover {
  opacity: 1;
}

#careers .img-gallery ul {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  gap: 40px;
}
#careers .img-gallery ul img {
  max-height: 284px;
}

#properties .single-property h3 {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #e3ce9a;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}
#properties .single-property .property-city {
  margin-top: 0;
  font-size: 15px;
  font-style: italic;
}

.case-studies > .post {
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 40px;
  margin-bottom: 40px;
}
.case-studies > .post .title,
.case-studies > .post .description {
  margin-bottom: 15px;
}

#properties .single-property div {
  min-height: 155px;
  height: 155px;
  width: 100%;
  background-size: cover;
}
#properties .single-property div img {
  height: 100%;
}

main#home .home-white-section .section-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
}

main#single-property .section-wrapper {
  display: grid;
  grid-template-columns: 55% 1fr;
  gap: 40px;
}
main#single-property .section-wrapper a {
  color: #113c64;
}
main#single-property .section-wrapper a:hover {
  color: #afa46a;
}
main#single-property .section-wrapper .images img {
  width: 100%;
  height: auto;
  display: block;
}
main#single-property .section-wrapper .images .thumbs {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}
main#single-property .section-wrapper .info {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
main#single-property .section-wrapper .info .row {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 10px;
}
main#single-property .section-wrapper .info .row .title {
  color: #012842;
  font-family: "Idealist Sans";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
}
main#single-property .section-wrapper .info .row .description {
  font-size: 15px;
}
main#single-property .section-wrapper .info .agent {
  margin-top: 40px;
}
main#single-property .section-wrapper .info .agent .name {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 17px;
}

main#services > section:not(.headline):not(.call-to-action) {
  padding-top: 60px;
  padding-bottom: 60px;
}
main#services > section:not(.headline):not(.call-to-action) h2 {
  font-size: 25px;
  margin-top: 50px;
  opacity: 0.5;
}
main#services > section:not(.headline):not(.call-to-action) h2:first-child {
  margin-top: 0;
}
main#services > section:not(.headline):not(.call-to-action) h3 {
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
}
main#services > section:not(.headline):not(.call-to-action) p {
  margin-bottom: 20px;
  line-height: 25px;
}

.services {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.services .title {
  margin-top: 25px;
  margin-bottom: 20px;
}

.white-color {
  color: white;
}

main#about section:not(.headline):not(.call-to-action) h3 {
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
}
main#about section:not(.headline):not(.call-to-action) h2 {
  font-size: 25px;
  margin-top: 50px;
  opacity: 0.5;
}
main#about #birtcher-anderson h2 {
  margin-bottom: 20px;
}
main#about p {
  line-height: 25px;
  margin-bottom: 20px;
}
main#about blockquote {
  text-align: right;
  font-weight: bold;
}
main#about #team .team-group .single-team > a figure {
  border-radius: 200px;
  border: 10px solid #e3ce9a;
  height: 200px;
  width: 200px;
  overflow: hidden;
  display: inline-block;
  transition: 0.3s;
}
main#about #team .team-group .single-team > a h4 {
  margin-top: 20px;
  font-size: 25px;
  transition: 0.3s;
}
main#about #team .team-group .single-team > a:hover figure {
  opacity: 0.8;
}
main#about #team .team-group .single-team > a:hover h4 {
  color: #afa46a;
}
main#about #team .team-group h3 {
  margin-top: 30px;
}
main#about .client-group,
main#about .partner-and-lenders-group,
main#about .award-slider {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 100px;
}

.members-category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.member-depertman-title {
  margin: 70px 0 40px 0 !important;
}

.client-group .list-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

#single-team .section-wrapper {
  display: grid;
  grid-template-columns: 35% 1fr;
  gap: 20px;
}
#single-team .section-wrapper .images {
  text-align: center;
}
#single-team .section-wrapper .images img {
  border-right: 10px solid #e3ce9a;
  border-bottom: 10px solid #e3ce9a;
}
#single-team .section-wrapper h3 {
  font-size: 23px;
  margin: 20px 0;
  opacity: 0.5;
  font-weight: 100;
}
#single-team .section-wrapper p {
  line-height: 27px;
}

.blue-menu > ul li {
  display: inline-block;
  position: relative;
}
.blue-menu > ul li:hover .blue-field-sub-menu {
  display: block;
}
.blue-menu > ul li .blue-field-sub-menu {
  z-index: 9;
  display: none;
  position: absolute;
  background-color: #113c64;
  top: 15px;
  padding: 40px 10px 10px 10px;
  width: 200px;
}
.blue-menu > ul li .blue-field-sub-menu li {
  padding-bottom: 10px;
}
.blue-menu > ul li .blue-field-sub-menu li:hover {
  color: #e3ce9a;
}

main#contact {
  padding-top: 50px;
  padding-bottom: 50px;
}
main#contact .contact-wrapper {
  display: flex;
  grid-template-columns: auto auto;
  align-items: center;
}
main#contact .contact-wrapper h2 {
  font-family: "Idealist Sans";
}
main#contact .contact-wrapper p {
  font-size: 16px;
  line-height: 24px;
}
main#contact .contact-wrapper .contact-top-part-txt {
  font-size: 20px;
}
main#contact .contact-wrapper .map-direction a {
  color: #afa46a;
  font-size: 20px;
}
main#contact .contact-wrapper .map-direction a:hover {
  color: #113c64;
}
main#contact .contact-wrapper .contact-form-field {
  margin-top: 30px;
}
main#contact .contact-wrapper .contact-form-field label {
  margin-bottom: 10px;
  display: inline-block;
  color: #012842;
}
main#contact .contact-wrapper .contact-form-field input[type=text],
main#contact .contact-wrapper .contact-form-field input[type=tel],
main#contact .contact-wrapper .contact-form-field input[type=email],
main#contact .contact-wrapper .contact-form-field textarea {
  width: 100%;
  margin-bottom: 20px;
  font-family: "Idealist Sans";
  font-weight: normal;
  background-color: #e3ce9a;
  color: #012842;
  box-sizing: border-box;
}
main#contact .contact-wrapper .contact-form-field input[type=text],
main#contact .contact-wrapper .contact-form-field input[type=tel],
main#contact .contact-wrapper .contact-form-field input[type=email] {
  min-height: 40px;
  padding-left: 10px;
}
main#contact .contact-wrapper .contact-form-field textarea {
  border: none;
  padding: 10px;
  resize: none;
}
main#contact .contact-wrapper .contact-form-field input[type=submit] {
  background-color: #113c64;
  color: white;
  width: 50%;
}
main#contact .contact-wrapper .contact-form-field input[type=submit]:hover {
  color: #113c64;
  background-color: white;
}

.description.blue-menu {
  display: none !important;
}

article {
  background-color: #e5ecef;
  padding: 50px 0;
}
article .post-thumbnail {
  display: inline-block;
}
article .post-thumbnail img {
  border-right: 10px solid #e3ce9a;
  border-bottom: 10px solid #e3ce9a;
}
article .entry-title {
  font-size: 29px !important;
  color: #012842;
  line-height: 37px;
  margin: 25px 0;
}
article p {
  font-size: 17px !important;
  line-height: 25px !important;
}

.mobile-menu {
  display: none;
}

.careers-accordion .accordion-container {
  position: relative;
  height: auto;
  width: 100%;
}
.careers-accordion .accordion-container > h2 {
  text-align: center;
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}
.careers-accordion .set {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
}
.careers-accordion .set > a {
  display: block;
  padding: 20px 15px;
  text-decoration: none;
  color: #555;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.careers-accordion .set > a i {
  float: right;
  margin-top: 2px;
}
.careers-accordion .set > a.active {
  background-color: #3399cc;
  color: #fff;
}
.careers-accordion .content {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display: none;
  padding: 20px;
}
.careers-accordion .content p {
  padding: 10px 15px;
  margin: 0;
  color: #333;
}

.accordion-main-title {
  font-size: 20px !important;
  color: #113c64;
  background-color: #e3ce9a;
  margin: 0 !important;
  padding: 20px;
  font-weight: bold;
}

.carousel-gallery {
  margin: 50px 0;
  padding: 0 30px;
}
.carousel-gallery .swiper-slide a {
  display: block;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.carousel-gallery .swiper-slide a:hover .image .overlay {
  opacity: 1;
}
.carousel-gallery .swiper-slide a .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.carousel-gallery .swiper-slide a .image .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.8);
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.carousel-gallery .swiper-slide a .image .overlay em {
  color: #fff;
  font-size: 26px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
}
.carousel-gallery .swiper-pagination {
  position: relative;
  bottom: auto;
  text-align: center;
  margin-top: 25px;
}
.carousel-gallery .swiper-pagination .swiper-pagination-bullet {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.carousel-gallery .swiper-pagination .swiper-pagination-bullet:hover {
  opacity: 0.7;
}
.carousel-gallery .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #d63031;
  transform: scale(1.1, 1.1);
}

.property-main-title {
  color: #e3ce9a;
  margin: 50px 0 30px 0;
}

header#main-header nav > ul > li > ul > li > a:hover {
  background-color: #e3ce9a !important;
  color: #113c64 !important;
}

header#main-header nav > ul > li > ul > li > a:focus {
  background-color: #e3ce9a !important;
  color: #113c64;
}

header#main-header nav > ul > li > ul > li > a[aria-current=page] {
  background-color: transparent;
  color: white;
}

@media only screen and (max-width: 1199px) {
  #home .news-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  #home .slick-arrow {
    position: absolute;
    top: -40%;
    font-size: 30px;
    cursor: pointer;
  }
  #home .slick-arrow.slick-next {
    right: 0px;
  }
  #home .slick-arrow.slick-prev {
    left: 0px;
  }
  header#main-header nav > ul > li > a {
    padding: 0px 15px;
  }
  .footr-wrapper.container::before {
    display: none;
  }
  .careers-warp .curr-open {
    width: 34%;
  }
}
@media only screen and (max-width: 991px) {
  header#main-header nav > ul > li > a {
    padding: 0 2px;
    font-size: 11px;
  }
  .post.porperty-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .footr-wrapper.container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .footr-wrapper.container div {
    margin-bottom: 50px;
  }
  main#contact .contact-wrapper {
    display: block;
  }
  main section.news > .news-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .case-studies > .post {
    display: grid;
    grid-template-columns: 1fr 200px;
  }
  .careers-warp .curr-open {
    width: 37%;
  }
  #careers .img-gallery ul {
    grid-template-columns: repeat(3, 1fr);
  }
  .single-prpoerty-list {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .propertyid-main-figure {
    text-align: center;
  }
  .propertyid-main-figure img {
    width: inherit;
  }
}
@media only screen and (max-width: 767px) {
  main section.headline {
    min-height: auto;
  }
  #home .news-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  #home .slick-arrow {
    position: absolute;
    top: 100%;
    font-size: 30px;
    cursor: pointer;
  }
  #home .slick-arrow.slick-prev {
    display: none !important;
  }
  #home .slick-dots {
    display: none !important;
  }
  #subheader .description {
    display: none !important;
  }
  main#home .home-white-section .section-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .golden-box {
    padding: 20px;
  }
  .mobile-menu {
    display: block;
    position: absolute;
    right: 20px;
    font-size: 30px;
    color: #e3ce9a;
    cursor: pointer;
  }
  header nav {
    display: none;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 9;
    background-color: #012842;
    width: 100%;
    height: auto !important;
  }
  header nav ul {
    padding-bottom: 30px;
  }
  header nav ul li {
    display: block !important;
    text-align: center;
    height: auto !important;
  }
  header nav ul li a {
    color: white !important;
    display: block;
    padding: 15px 10px !important;
    font-size: 15px !important;
  }
  header nav ul li .sub-menu {
    display: none !important;
  }
  .copyright {
    width: 50%;
  }
  .services {
    display: block;
  }
  main#services > section:not(.headline):not(.call-to-action) {
    padding-top: 20px;
    padding-bottom: 18px;
  }
  main#about #team .team-group {
    grid-template-columns: repeat(2, 1fr);
  }
  main#about .client-group,
  main#about .partner-and-lenders-group,
  main#about .award-slider {
    display: block;
  }
  main#about .client-group {
    margin-bottom: 0;
  }
  .careers-warp .curr-open {
    width: 100%;
  }
  .careers-warp {
    min-height: 630px !important;
  }
  .post.porperty-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
    display: grid;
    gap: 40px;
  }
  .members-category {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  .client-group .list-column {
    grid-template-columns: repeat(2, 1fr);
  }
  #single-taem .section-wrapper .grid-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media only screen and (max-width: 479px) {
  header#main-header .topbar > .topbar-wrapper {
    display: block;
    height: 50px;
  }
  header#main-header .topbar > .topbar-wrapper a {
    display: block;
    margin: 10px 0;
  }
  .post.single-team {
    text-align: center;
  }
  .client-group .list-column {
    grid-template-columns: repeat(1, 1fr);
  }
  .members-category {
    grid-template-columns: repeat(1, 1fr);
  }
  main#home .home-white-section .section-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .footr-wrapper.container {
    grid-template-columns: repeat(1, 1fr) !important;
    text-align: center;
  }
  main#about #team .team-group {
    grid-template-columns: repeat(1, 1fr);
  }
  main#about .partner-and-lenders-group {
    margin-bottom: 0;
  }
  header nav {
    top: 130px !important;
  }
  main section.news > .news-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .case-studies > .post {
    display: inherit;
  }
  .case-studies > .post .info {
    display: block;
  }
  .case-studies > .post figure {
    display: block;
  }
  .careers-warp {
    min-height: 870px !important;
  }
  .post.porperty-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
    display: grid;
    gap: 40px;
  }
  #careers .img-gallery ul {
    grid-template-columns: repeat(2, 1fr);
  }
  #careers .accordion-main-title {
    font-size: 14px !important;
  }
}
.single-prpoerty-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.post.porperty-wrapper {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 40px;
}
.post.porperty-wrapper h3.title {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #e3ce9a;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
}
.slick-arrow.slick-next {
  right: -80px;
}
.slick-arrow.slick-prev {
  left: -80px;
}

#home section.headline > .headline-wrapper p {
  font-size: 22px !important;
  line-height: 31px;
}
#home .slick-dots {
  display: inline-block;
  position: absolute;
  bottom: -100px;
}
#home .slick-dots li {
  display: inline-block;
}
#home .slick-dots li:before {
  display: none;
}
#home .slick-dots li button {
  color: #012842;
  background-color: #e3ce9a;
  border: none;
  padding: 13px;
  margin-left: 10px;
}
#home .slick-dots .slick-active button {
  color: #e3ce9a !important;
  background-color: #012842 !important;
}

.accordion-title {
  text-align: center;
  font-size: 23px;
  font-weight: 600 !important;
  color: #353535;
  opacity: 1 !important;
}

.property-info .row {
  margin: 30px 0;
  grid-template-columns: 35% 1fr;
  display: grid;
  gap: 40px;
}
.property-info .title {
  color: #012842;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
}
.property-info .description {
  font-size: 15px;
}

.img-gallery li::before {
  display: none;
}
.img-gallery li img {
  width: 100%;
}
.img-gallery ul {
  grid-template-columns: repeat(3, 1fr);
  display: grid !important;
  gap: 40px !important;
  margin-top: 30px;
}

footer#main-footer > .disclosure ul > li::before {
  content: "";
  font-size: 16px;
  font-style: italic;
  padding-left: 30px;
  background-image: url(/wp-content/themes/davispartners/images/icon-s.png);
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 50%;
}

.footr-wrapper.container::before {
  display: none;
}

footer#main-footer > .disclosure ul {
  display: block;
}

#single-taem .section-wrapper .grid-2 {
  display: grid;
  grid-template-columns: 35% 1fr;
  gap: 20px;
  margin-top: 30px;
}
#single-taem .member-photo figure {
  width: 100%;
}
#single-taem .member-photo img {
  border-right: 10px solid #e3ce9a;
  border-bottom: 10px solid #e3ce9a;
  height: auto;
  width: auto;
}
#single-taem .member-info h3,
#single-taem .member-info h4,
#single-taem .member-info h5,
#single-taem .member-info h6 {
  font-size: 23px;
  margin: 20px 0;
  opacity: 0.5;
  font-weight: 100;
}
#single-taem .member-info p {
  line-height: 27px;
}

.headline-logos {
  text-align: center;
  margin-top: 20px;
}
.headline-logos figure {
  display: inline-block;
  margin-left: 20px;
}
.headline-logos figure img {
  height: auto;
  width: 200px;
}